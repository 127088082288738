@import "utils";

.hexagons {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 90%;
  bottom: 140px*$scale;
  right: -$spacing;

  .item {
    position: absolute;
    pointer-events: none;
    width: 315px*$scale;
    height: 315px*$scale;

    &__1 {
      left: 459px*$scale;
      bottom: 148px*$scale;
    }

    &__2 {
      left: 699px*$scale;
      bottom: 287px*$scale;
    }

    &__3 {
      left: 699px*$scale;
      bottom: 10px*$scale;
    }

    &__4 {
      left: 939px*$scale;
      bottom: 425px*$scale;
    }

    &__5 {
      left: 939px*$scale;
      bottom: 148px*$scale;
    }

    &__6 {
      left: 1179px*$scale;
      bottom: 564px*$scale;
    }

    &__7 {
      left: 1179px*$scale;
      bottom: 287px*$scale;
    }

    &__8 {
      left: 1179px*$scale;
      bottom: 10px*$scale;
    }

    &__9 {
      left: 1419px*$scale;
      bottom: 425px*$scale;
    }

    &__10 {
      left: 1419px*$scale;
      bottom: 148px*$scale;
    }
  }
}