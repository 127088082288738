.main-wrapper {
	padding: $spacing;
	position: relative;
	background: $brand;
	overflow: hidden;
	width: $desktop-screen-width;
	height: $desktop-screen-height;

	@include max-screen($small-screen-width) {
		padding: $spacing-tablet;
		width: $small-screen-width;
		height: $small-screen-height;
	}
}