@import "utils";

.textBox {
  width: 35%;
  position: absolute;
  top: $spacing;
  left: $spacing;
  z-index: 5;

  p {
    font-size: 1.5rem*$scale;
    line-height: 2rem*$scale;
    margin-bottom: 15px*$scale;

    sup {
      font-size: 1.5rem*$scale;
    }
  }

  &.large {
    p {
      font-size: 2.25rem*$scale;
      line-height: 2.9375rem*$scale;
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 42px * $scale;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 15px*$scale;
  }
}