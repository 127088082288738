@import "utils";

.hexagon {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
  z-index: 1;
  width: 100%;
  height: auto;

  path {
    fill:#0C499C;
    fill-opacity: 0.8;
    stroke: white;
    stroke-width: 1.5%;
    stroke-miterlimit: 10;
    transition: .5s;
  }
}