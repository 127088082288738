@import 'utils';

.menu {
  position: absolute;
  z-index: 1000;
  bottom: $spacing;
  left: 0;
  padding: 0 $spacing;
  width: 100%;

  &:before {
    content: "";
    height: 2px*$scale;
    width: 49.5%;
    position: absolute;
    display: block;
    background: $menu-line;
    left: -46.5%;
    bottom: 22px*$scale;
    z-index: 1;
  }

  &:after {
    content: "";
    height: 2px*$scale;
    width: 69.7%;
    position: absolute;
    display: block;
    background: $menu-line;
    right: -46.5%;
    bottom: 22px*$scale;
    z-index: 1;
  }

  &.hasExtraLink {
    &:after {
      content: "";
      height: 2px*$scale;
      width: 49.5%;
      position: absolute;
      display: block;
      background: $menu-line;
      right: -46.5%;
      bottom: 22px*$scale;
      z-index: 1;
    }
  }

  ul {
    display: flex;
    position: relative;
    z-index: 2;

    li {
      margin-right: 40px*$scale;
      font-size: 1.125rem*$scale;
      position: relative;
      width: 163px*$scale;

      &:after {
        content: "";
        height: 2px*$scale;
        width: 45px*$scale;
        position: absolute;
        display: block;
        background: $menu-line;
        left: 162px*$scale;
        bottom: 22px*$scale;
        z-index: 1;
      }

      &:hover, &:active, &.current {

        a {
          color: $menu-link-color-hover;
        }
        path {
          fill-opacity: 1!important;
          fill: $menu-link-back-hover!important;
        }
      }

      &.extraLink {
        align-self: flex-end;
        margin-right: 0;
        margin-left: auto;

        &:before {
          content: "";
          height: 2px*$scale;
          width: 143%;
          position: absolute;
          display: block;
          background: $menu-line;
          right: 162px*$scale;
          bottom: 22px*$scale;
          z-index: 1;
        }
      }

      a {
        color: $white;
        position: relative;
        z-index: 2;
        text-align: center;
        width: 163px*$scale;
        display: block;
        padding: 10px*$scale 0;
      }
    }
  }
}
