@import "utils";

.loadingSpinner {
  height: 100px * $scale;
  width: auto;
  animation: spin .7s linear infinite;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.centerInParent {
  position: absolute;
  top: 50%;
  left: 50%;
}

.hidden {
  display: none;
}