@import "utils";


@keyframes ping {
  75%, 100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

.textBox {
  width: 70%;
  z-index: 0;
}

.diagram {
  position: absolute;
  height: 612px*$scale;
  width: 1377px*$scale;
  top: 297px*$scale;
  left: $spacing;

  h2 {
    font-size: 1.75rem*$scale;
    line-height: 2rem*$scale;
    font-weight: bold;
    margin-top: -60px*$scale;
  }

  &__dot {
    display: block;
    height: 20px*$scale;
    width: 20px*$scale;
    right: -10px*$scale;
    top: -10px*$scale;
    position: absolute;
    background: $pink;
    border-radius: 100%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 100%;
      background: $pink;
      opacity: 1;
      z-index: -1;
    }
  }

  .ping:before {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  &__item {
    background: rgba(12, 73, 156, 0.8);
    border: 2px*$scale solid $white;
    height: 73px*$scale;
    width: 646px*$scale;
    display: flex;
    padding: 0 10px*$scale;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    transition: z-index;
    transition-duration: 0s;
    z-index: 0;
    transition-delay: .21s;
    cursor: pointer;

    //@include link-hover {
    //  background: $white;
    //
    //  h3 {
    //    color: $med-blue;
    //  }
    //}

    h3 {
      font-size: 1.5rem*$scale;
      line-height: 2rem*$scale;
      text-align: center;
    }

    &__5,
    &__6 {
      height: 74px*$scale;
      width: 280px*$scale;
    }

    &__3,
    &__7 {
      height: 132px*$scale;
      width: 228px*$scale;
    }

    &__1 {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      &:before {
        content: "";
        display: block;
        background: url("../../assets/img/arrow-small.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -60px*$scale;
        height: 37px*$scale;
        width: 93px*$scale;
        pointer-events: none;
      }
    }

    &__2 {
      left: 50%;
      transform: translateX(-50%);
      bottom: 150px*$scale;

      &:before {
        content: "";
        display: block;
        background: url("../../assets/img/arrow-small.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -60px*$scale;
        height: 37px*$scale;
        width: 93px*$scale;
        pointer-events: none;
      }
    }

    &__4 {
      left: 50%;
      transform: translateX(-50%);
      bottom: 300px*$scale;

      &:after {
        content: "";
        display: block;
        background: url("../../assets/img/arrow-small.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 100px*$scale;
        top: -60px*$scale;
        height: 37px*$scale;
        width: 93px *$scale;
        pointer-events: none;
      }

      &:before {
        content: "";
        display: block;
        background: url("../../assets/img/arrow-small.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 100px*$scale;
        top: -60px*$scale;
        height: 37px*$scale;
        width: 93px*$scale;
        pointer-events: none;
      }
    }

    // Data Acquisition
    &__3 {
      bottom: 125px*$scale;
      left: 40px*$scale;

      &:after {
        content: "";
        display: block;
        background: url("../../assets/img/arrow-small.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: -100px*$scale;
        height: 37px*$scale;
        width: 93px*$scale;
        transform: rotate(90deg);
        pointer-events: none;
      }

      .diagram__popup {
        bottom: 82px*$scale;
      }
    }

    &__6 {
      bottom: 450px*$scale;
      left: 33.5%;
      transform: translateX(-33.5%);
    }

    &__5 {
      bottom: 450px*$scale;
      left: 67%;
      transform: translateX(-67%);

      &:after {
        content: "";
        display: block;
        background: url("../../assets/img/arrow-small.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: -90px*$scale;
        height: 37px*$scale;
        width: 93px*$scale;
        transform: rotate(270deg);
        pointer-events: none;
      }
    }

    // Foundation systems
    &__7 {
      right: 50px*$scale;
      top: 210px*$scale;

      &:after {
        content: "";
        display: block;
        background: url("../../assets/img/arrow-small.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: -90px*$scale;
        height: 37px*$scale;
        width: 93*$scale;
        transform: rotate(270deg);
        pointer-events: none;
      }
    }
  }
}

.largeArrow {
  background: url("../../assets/img/arrow-large.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 67px * $scale;
  top: -5px * $scale;
  height: 184px*$scale;
  width: 835px*$scale;
  pointer-events: none;
}

.deliversList {
  position: absolute;
  right: $spacing;
  top: 297px*$scale;
  width: 466px*$scale;
  z-index:1;

  h2 {
    margin-top: -60px*$scale;
    margin-bottom: 30px*$scale;
  }


  &__item {
    cursor: pointer;
    background: white;
    color: $light-blue;
    padding: 10px*$scale 15px*$scale;
    display: block;
    font-size: 1.5rem*$scale;
    margin-bottom: 10px*$scale;
    position: relative;
  }

  .active {
    box-shadow: inset 4px * $scale 0 0 0 $pink;
  }

}

.highestStack {
  z-index: 110;
  transition-delay: 0s;

}