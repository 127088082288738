h1, h2, h3, h4, h5, h6, p {
  color: $heading-color;
  font-weight: 400;
}

h1 {
  font-family: $primary-font;
  font-size: 2.5rem*$scale;
  line-height: 2.7;

  @include max-screen(768px) {
    font-size: 2rem*$scale;
    line-height: 2.5rem*$scale;
  }
}

h2 {
  font-family: $primary-font;
  font-size: 1.75rem*$scale;
  line-height: 2rem*$scale;
  font-weight: bold;

  @include max-screen(768px) {
    font-size: 1.8rem*$scale;
    line-height: 2.2rem*$scale;
  }
}

h3 {
  font-family: $primary-font;
  font-size: 1.8rem*$scale;
  line-height: 2.2rem*$scale;

  @include max-screen(768px) {
    font-size: 1.2rem*$scale;
    line-height: 1.7rem*$scale;
  }
}

h4, h5, h6 {
  font-family: $primary-font;
  font-size: 1.5rem*$scale;
  line-height: 2rem*$scale;

  @include max-screen(768px) {
    font-size: 1.2rem*$scale;
    line-height: 1.7rem*$scale;
  }
}

p {
  font-family: $primary-font;
  font-size: 1rem*$scale;
  line-height: 1.5rem*$scale;
}