@import "utils";

.item {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &.bigIcon {
    .inner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text {
      font-size: 1.5rem * $scale;
      margin-top: 20px * $scale;
    }
  }

  .inner {
    position: absolute;
    padding: 13%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .icon {
    height: 100px*$scale;
    margin: 0 auto;
    pointer-events: none;
  }

  .text {
    margin-top: 15px * $scale;
    font-size: 1.25rem*$scale;
    line-height: 1.6rem*$scale;
    pointer-events: none;
    text-align: center;
    color: white;
  }

  span {
    &:active, &:hover {
      .hexagon-svg path {
        fill: $light-blue;
      }
    }
  }

  &.showHidden {
    .hiddenItem {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  .hiddenItem {
    visibility: hidden;
    color: white;
    opacity: 0;
    pointer-events: none;
    transform: scale(1.1);
    transition: visibility .3s 0s, opacity .3s ease, transform .3s ease-out;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;

    .hiddenText {
      position: absolute;
      margin-top: 0;
      padding: 0 16%;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $med-blue;
      font-size: 1.25rem*$scale;
      line-height: 1.6rem*$scale;
      pointer-events: none;
      text-align: center;
    }

    .close {
      background: $med-blue;
      display: block;
      color: $white;
      position: absolute;
      bottom: 33px*$scale;
      right: 80px*$scale;
      z-index: 10;
      font-size: 1.125rem*$scale;
      padding: 3px*$scale 15px * $scale;
      clip-path: polygon(15% 0, 85% 0, 100% 50%, 85% 100%, 15% 100%, 0% 50%);
    }
  }
}