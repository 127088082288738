@import "utils";

$line-thickness: 5px * $scale;
$line-width: 140px * $scale;
$transition-speed: .25s;
$transition-delay: .125s;
$box-line-space: 12px * $scale;
$box-align-space: 50px * $scale;

.wrapper {
  height: $line-thickness;
  width: $line-width;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 100;
  pointer-events: none;
  cursor: default;

  &.show {
    pointer-events: auto;
  }

  &.leftOfTarget {
    right: 100%;
  }

  &.rightOfTarget {
    left: 100%;
  }
}

.line {
  pointer-events: none;
  height: 100%;
  background: $pink;
  position: absolute;
  transition: left $transition-speed ease, right $transition-speed ease;
  transition-delay: $transition-delay;
  top: 0;
  bottom: 0;
  cursor: default;

  &.show {
    transition-delay: 0s;
  }

  &.leftOfTarget {
    right: 0;
    left: 100%
  }

  &.rightOfTarget {
    left: 0;
    right: 100%;
  }

  &.show.leftOfTarget {
    left: 0;
  }

  &.show.rightOfTarget {
    right: 0;
  }
}

.box {
  width: 398px * $scale;
  padding: 19px * $scale;
  background: rgb(12,73,156);
  position: absolute;
  color: white;
  opacity: 0;
  transition: transform $transition-speed ease, opacity $transition-speed ease;
  transition-delay: 0s;
  transform: translateY(10%);
  pointer-events: none;

  &.show {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
    transition-delay: $transition-delay;
  }

  &:before {
    content: '';
    display: block;
    background: $pink;
    width: $line-thickness;
    //height: 100%;
    position: absolute;
    transition: top $transition-speed ease, bottom $transition-speed ease;
  }

  &.show:before {
    transition-delay: $transition-delay;
  }

  &.leftOfTarget {
    margin-right: $box-line-space;
    right: 100%;

    &:before {
      right: -$box-line-space;
    }
  }

  &.rightOfTarget {
    margin-left: $box-line-space;
    left: 100%;

    &:before {
      left: -$box-line-space;
    }
  }

  &.isLarge {
    width: 1000px * $scale;
  }

  &.isMedium {
    width: 700px * $scale;
  }

  &.alignBottom {
    bottom: -$box-align-space;
  }

  &.alignTop {
    top: -$box-align-space;
  }

  &.alignMiddle {
    transform: translateY(-40%);
  }

  &.show.alignMiddle {
    transform: translateY(-50%);
  }

  //box line animations
  &.alignBottom:before,
  &.alignMiddle:before {
    bottom: 0;
    top: 100%
  }

  &.alignTop:before {
    top: 0;
    bottom: 100%
  }

  &.show.alignBottom:before,
  &.show.alignMiddle:before{
    top: 0;
  }

  &.show.alignTop:before {
    bottom: 0
  }

}

.icon {
  height: 71px * $scale;
}

.title {
  font-size: 36px * $scale;
  line-height: 1.111;
}

.text {
  font-size: 22px * $scale;
  line-height: 1.454;
  margin-top: 20px * $scale;
}

.button {
  pointer-events: none;
  &.show {
    pointer-events: auto
  }
  appearance: none;
  border: none;
  cursor: pointer;
  background: $white;
  color: $med-blue;
  height: 45px * $scale;
  min-width: 162px * $scale;
  display: block;
  margin-left: auto;
  margin-top: 30px * $scale;
  font-size: 18px*$scale;
  padding: 3px*$scale 15px * $scale;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
  transition: background .3s ease, color .3s ease;

  &:hover,
  &:active {
    background-color: darken(white, 10%);
  }
}
