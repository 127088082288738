.hide-text {
	text-indent: -9999px;
}

.clear {
    clear: both;
    display: block;
  color: black;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.kvfysmfp {
  // overrides https://github.com/retyui/react-quick-pinch-zoom/blob/5cb0c4d4fa270c27b54588e34349b6e68362cded/src/PinchZoom/styles.css.ts
  overflow: unset !important;
}
