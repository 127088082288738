$scale: 1;

// Breakpoints

$menu-mobile-breakpoint: 1024px;

// Fonts

$base-font-size:        16px;
$base-line-height:      1.5;
$letter-spacing:        0.5px;

// Other

$base-radius:       2px;

// Transitions

$transition-time:   0.3s;

// Spacing

$spacing: 50px*$scale;
$spacing-tablet: 31px;

// Largest

$largest-screen-width: 3840px;
$largest-screen-height: 2160px;

// Main desktop
$desktop-screen-width: 1920px;
$desktop-screen-height: 1080px;

// iPad Pro 11 inch
$small-screen-width:  1366px;
$small-screen-height:  1024px;

