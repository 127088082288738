@import "utils";

.qrCode {
  width: 70%;
  margin-top: 10%;
}

.textBox {
  width: 40%;
}

.labelRow {
  display: flex;
  margin: 20px*$scale 0;
}

.icon {
  flex-shrink: 0;
  margin-top: 3px*$scale;
  width: 30px*$scale;
  height: 30px*$scale;
  margin-bottom: -5px*$scale;
  margin-right: 12px*$scale;
}

.formEmbed {
  background: white;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.2);
  border-radius: 4px * $scale;
  box-shadow: 0 0 #0000, 0 0 #000, var(--tw-shadow);
  position: absolute;
  right: $spacing;
  margin: 0 auto;
  top: 24%;
  height: 695px * 0.8;
  width: 1100px * 0.8;
  border: 0;
  transform: scale($scale * 1.15);
  transform-origin: 100% 0;
  opacity: 0;
  transition: top .3s ease, opacity .3s ease;
}

.formEmbed__loaded {
  top: 22%;
  opacity: 1
}

.formEmbed__spinner {
  position: absolute;
  top: 50%;
  left: 71%;
}