@import "utils";

.hexagonBlock {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 90%;
  bottom: 140px*$scale;
  right: -$spacing;

  &__item {
    width: 315px*$scale;
    height: 315px*$scale;
    position: absolute;
    pointer-events: none;

    p {
      position: absolute;
      left: 20%;
      top: 50%;
      transform: translateY(-50%);
      right: 10%;
      font-size: 1.5rem*$scale;
      line-height: 2rem*$scale;
      margin-bottom: 0;
      pointer-events: none;
    }

    a {
      &:active, &:hover {
        path {
          fill: $light-blue;
        }
      }
    }

    &.first {
      left: 0;
      bottom: 9px*$scale;
    }

    &.second {
      left: 239px*$scale;
      bottom: 148px*$scale;
    }

    &.third {
      left: 479px*$scale;
      bottom: 286px*$scale;
    }

    &.fourth {
      left: 479px*$scale;
      bottom: 9px*$scale;
    }

    &.fifth {
      left: 719px*$scale;
      bottom: 148px*$scale;
    }

    &.videoContainer {
      left: 955px*$scale;
      bottom: 100px*$scale;
      height: 687px*$scale;
      width: 795px*$scale;

      video {
        object-fit: cover;
        overflow: hidden;
        position: absolute;
        transform: scale(0.981);
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
        z-index: 2;
        opacity: 1;
        transition: opacity .4s ease;

        &.hideVideo {
          opacity: 0
        }
      }

      .videoButton {
        position: absolute;
        z-index: 10;
        bottom: -22px*$scale;
        left: 28%;
        opacity: 1;
        transition: left .3s ease, opacity .3s ease;
        pointer-events: auto;

        &.hidden {
          opacity: 0;
          left: 36%;
          pointer-events: none;
        }

        &__inner {
          height: 100%;
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0 24px * $scale;
          background-color: $hexagon-blue;
          color: white;
          transition: background-color .3s ease, color .3s ease;
          line-height: 1.0;

          &:hover,
          &:active {
            background-color: white;
            color: $hexagon-blue;

            path {
              fill: $hexagon-blue;
            }

          }
        }

        &__icon {
          width: 34px * $scale;
          margin-right: 10px * $scale;

          path {
            transition: fill .3s ease;
          }
        }
      }

      .cancelVideoButton {
        left: unset !important;
        right: 28%;
        font-size: 29px * $scale;
        pointer-events: auto;
        transition: right .3s ease, opacity .3s ease;

        &__icon {
          width: 20px * $scale;
          margin-right: 10px * $scale;
        }

        &.hidden {
          right: 35%;
          pointer-events: none !important;
        }
      }
    }
  }
}