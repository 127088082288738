@import "utils";

html {
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-family: $primary-font;
  padding: 0;
  margin: 0;
  text-rendering: auto;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  background: $main-background;
  overflow: hidden;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

main, section, header, footer, aside {
  display: block;
}

#root {
  height: 100vh;
  width: 100vw;
  /* center #screen vertically and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;
}

:root {
  --scale: #{$scale};
}

.page {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
