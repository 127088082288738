@import "utils";

.hexagons {
  position: absolute;
  z-index: 10;
  width: 95%;
  height: 90%;
  bottom: 140px*$scale;
  right: $spacing;

  &__item {
    width: 283px*$scale;
    height: 283px*$scale;
    position: absolute;
    transition: opacity .3s ease, transform .3s ease;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      opacity: 0.3;
      transform: scale(0.91);

      .shape > path {
        pointer-events: none !important;
      }
    }

    .shape {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    .image {
      pointer-events: none;
      opacity: 0.9;
      height: 240px * $scale;
      width: 100%;
      object-fit: cover;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      position: absolute;
    }

    p {
      position: absolute;
      z-index: 5;
      font-weight: bold;
      left: 17%;
      bottom: 4px * $scale;
      right: 17%;
      font-size: 1.375rem*$scale;
      line-height: 2rem*$scale;
      margin-bottom: 50px * $scale;
      pointer-events: none;
      text-align: center;
      white-space: pre;
    }

    &__1 {
      left: 1030px*$scale;
      top: 198px*$scale;

    }

    &__2 {
      left: 597px*$scale;
      top: 446px*$scale;
    }

    &__3 {
      left: 597px*$scale;
      top: 696px*$scale;
    }

    &__4 {
      left: 1246px*$scale;
      top: 322px*$scale;
    }

    &__5 {
      left: 1246px*$scale;
      top: 572px*$scale;
    }

    &__6 {
      left: 814px*$scale;
      top: 572px*$scale;
    }

    &__7 {
      left: 597px*$scale;
      top: 196px*$scale;

    }

    &__8 {
      top: 198px*$scale;
      left: 1462px*$scale;
    }

    &__9 {
      top:447px*$scale;
      left: 1462px*$scale;
    }

    &__10 {
      left: 814px*$scale;
      top: 322px*$scale;
    }

    &__11 {
      left: 1030px*$scale;
      top: 696px*$scale;
    }

    &__12 {
      left: 1030px*$scale;
      top: 446px*$scale;
    }

    &__13 {
      left: 1462px*$scale;
      top: 696px*$scale;
    }
  }
}

.navLeft {
  position: absolute;
  top: 210px*$scale;
  left: $spacing;
  z-index: 20;
  width: 466px*$scale;
  margin-bottom: 40px*$scale;

  p {
    font-size: 1.125rem*$scale;
    line-height: 1;
    margin-bottom: 15px*$scale;
  }

  .btn {
    cursor: pointer;
    background: rgba(12, 73, 156, 0.8);
    color: $white;
    padding: 10px*$scale 15px*$scale;
    display: block;
    font-size: 1.5rem*$scale;
    margin-bottom: 10px*$scale;
    transition: opacity .3s ease;

    @include link-hover {
      background: $white;
      color: $med-blue;
    }

    &.disabled {
      opacity: 0.4
    }
  }

  .reset {
    cursor: pointer;
    background: rgba(12, 73, 156, 0.8);
    color: $white;
    padding: 10px*$scale 50px*$scale;
    display: block;
    font-size: 1.5rem*$scale;
    border: 0;
    margin: 7px * $scale 10px * $scale 0 auto;
    transition: opacity .3s ease;

    &:hover,
    &:active {
      background: $white;
      color: $med-blue;
    }
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.navLeftBottom {
  margin-top: 30px * $scale;

  .btnWrapper {
    display: flex;
  }
  .btn {
    flex: 1;
    margin-right: 10px*$scale;
  }
}