@import "utils";

$wrapper-inset: 20px * $scale;
$inner-inset: 18px * $scale;

@mixin hexagon-clip($inset) {
  clip-path: polygon(0 50%, $inset 0, calc(100% - #{$inset}) 0, 100% 50%, calc(100% - #{$inset}) 100%, $inset 100%);
}

.wrapper {
  height: 52px * $scale;
  padding: 3px * $scale;
  background: white;
  @include hexagon-clip($wrapper-inset);
}

.paddingInner {
  .inner {
    padding: 0 22px * $scale;
  }
}

.flexInner {
  .inner {
    display: flex;
    align-items: center;
  }
}

.inner {
  height: 100%;
  width: 100%;
  font-size: 19px * $scale;
  color: white;
  background: $hexagon-blue;
  @include hexagon-clip($inner-inset);
}