@import "utils";

.btn {
  pointer-events: none;
  background: rgba(black, 0.6);
  height: 180px * $scale;
  width: 180px * $scale;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px * $scale;
  transition: opacity .25s ease;
}

.icon {
  height: 100%;
  width: auto;
}

.hidden {
  opacity: 0;
}