@import "utils";

#screen {
  /* screen is 4:3 */

  //outline: 10px solid yellow;
  width: calc(1920px * var(--scale));
  height: calc(1440px * var(--scale));

  /* center #safe-area vertically and horizontally */
  display: flex;
  align-items: center;
}

#safe-area {
  /* safe-area is 16:9 centered in #screen */

  //outline: 10px solid green;
  width: calc(1920px * var(--scale));
  height: calc(1080px * var(--scale));
  position: relative;
  padding: 0;
}

/* Transition Group CSS */

$timing: 0.4s;
$distance: 7vw;

.fade-enter {
  opacity: 0;
  transform: translateX($distance);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: opacity $timing ease, transform $timing ease;
}

.fade-exit {
  opacity: 1;
  transform:translateX(0px);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(-$distance);
  transition: opacity $timing ease, transform $timing ease;
}
