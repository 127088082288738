@import "utils";

$strokeWidth: 3;

.hexagon {
  fill: none;
  stroke: none;
  height: 100%;
  width: 100%;
  pointer-events: none;

  &__noPointerEvents {
    .hexagonBase {
      pointer-events: none;
    }
  }

  &__whiteFill {
    .hexagonBase {
      fill: white;
      fill-opacity: 1;
      &:hover {
        fill: white;
      }
    }
  }

  &__strokeOnly {
    .hexagonBase {
      fill: none;
      &:hover {
        fill: none;
      }
    }
  }
}

.hexagonBase {
  cursor: pointer;
  transition: fill .2s ease;
  pointer-events: fill;
  fill: $hexagon-blue;
  fill-Opacity: 0.8;
  stroke: white;
  stroke-opacity: 0.7;
  stroke-width: $strokeWidth;
  stroke-miterlimit: 1;

  &:hover {
    fill: $light-blue;
  }
}

@keyframes glow-animation {
  0% {
    stroke-dashoffset: 80;
  }
  30% {
    stroke-dashoffset: -934.61;
  }
  100% {
    stroke-dashoffset: -934.61;
  }
}

.hexagonGlow {
  animation: glow-animation 5000ms linear infinite normal forwards;
  animation-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
  animation-delay: 2s;
  fill: none;
  pointer-events: none;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: $strokeWidth;
  stroke-miterlimit: 1;
  stroke-dashoffset: 80;
  stroke-dasharray: 80, 934.61;
}

