// Palette

$white:		      #FFFFFF;
$black: 	      #000000;
$dark-green:      #0A293B;
$light-blue:      #0080C9;
$med-blue:        #1A3D8F;
$hexagon-blue:    #0C499C;
$red:             red;
$blue:            #0E122D;
$grey-light:      rgba(0,0,0,0.1);
$pink:            #E71F85;

// Theme styles

$brand:             $blue;

$font-color:        $white;
$heading-color:     $white;
$link-color:        $white;
$link-hover-color:  $white;

$border-color:      rgba(0,0,0,0.5);

$main-background:   $light-blue;

$particle-background: $light-blue;

// Menu

$menu-link-color: $white;
$menu-link-back:  rgba(26,61,143,0.8);
$menu-link-back-hover: $white;
$menu-link-color-hover: $med-blue;
$menu-line: $white;