@import "utils";

.textBox {
  width: 30%;
}

.features {
  width: 300px*$scale;
  position: absolute;
  left: $spacing;
  bottom: 150px * $scale;

  &__item {
    background-color: rgba(12, 73, 156, 0.8);
    margin-bottom: 10px*$scale;
    padding: 5px*$scale;
    position: relative;
    cursor: pointer;
  }

  .disabled {
    opacity: 0.4;
    cursor: default;
  }
}

.outcomes {
  color: white;
  font-size: 1.6rem*$scale;
  line-height: 1.4;
  position: absolute;
  right: $spacing;
  top: $spacing;
  width: auto;
  max-width: 55%;
  text-align: right;
  background: rgba($med-blue, 0.3);
  padding: 15px * $scale 20px * $scale;
}

.videoContainer {
  position: absolute;
  right: $spacing;
  bottom: 150px*$scale;
  width: 1195px*$scale;
  padding-bottom: 34.91%;
  border: 2px * $scale solid white;
  background-color: rgba($med-blue, 0.35);

  video {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .videoButton {
    position: absolute;
    z-index: 10;
    right: 8%;
    bottom: 0;
    transform: translateY(50%);
    height: 52px * $scale;
    overflow: hidden;

    &__disabled {
      pointer-events: none;

      img {
        pointer-events: none;
        opacity: 0.4;
      }
    }

    img {
      @include unselectable;
      height: 38px * $scale;
      width: auto;
      overflow: hidden;
      cursor: pointer;
      transition: opacity .2s ease, transform .4s ease;

      &:active {
        transform: scale(0.7)
      }

      &:not(:first-child) {
        margin-left: 14px * $scale;
      }
    }
  }
}
