@import "utils";

.hexagons {
  position: absolute;
  width: 100%;
  height: 90%;
  bottom: 200px*$scale;
  left: 150px*$scale;

  .item {
    width: 315px*$scale;
    pointer-events: none;
    height: 315px*$scale;
    position: absolute;

    &__1 {
      left: 0;
      top: 350px*$scale;
    }

    &__2{
      left: 0;
      top: 630px*$scale;
    }

    &__3 {
      left: 480px*$scale;
      top: 630px*$scale;
    }

    &__4 {
      left: 480px*$scale;
      top: 350px*$scale;
    }

    &__center {
      left: 240px*$scale;
      top: 491px*$scale;

      b {
        display: inline-block;
        margin-bottom: 10px * $scale;
      }

      p {
        position: absolute;
        left: 12%;
        top: 53%;
        transform: translateY(-50%);
        right: 12%;
        font-size: 1.375rem*$scale;
        line-height: 1.6rem*$scale;
        margin-bottom: 0;
        pointer-events: none;
        text-align: center;
      }
    }
  }
}

.hexagonVideo {
  position: absolute;
  height: 687px*$scale;
  width: 795px*$scale;
  right: 150px*$scale;
  top: 210px*$scale;

  &__overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0.9828);
    cursor: pointer
  }

  .videoButton {
    position: absolute;
    right: 27%;
    bottom: -22px * $scale;
    height: 52px * $scale;
    overflow: hidden;
    z-index: 10;

    &__disabled {
      pointer-events: none;

      img {
        pointer-events: none;
        opacity: 0.4;
      }
    }

    img {
      @include unselectable;
      height: 38px * $scale;
      width: auto;
      overflow: hidden;
      cursor: pointer;
      transition: opacity .2s ease, transform .4s ease;

      &:active {
        transform: scale(0.7)
      }

      &:not(:first-child) {
        margin-left: 14px * $scale;
      }
    }
  }
}