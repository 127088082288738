@mixin clearfix {
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

@mixin link-hover() {
  &:hover,
  &:focus,
  &:active {
    @content
  }
}

@mixin absolute-center {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: absolute;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin flex-vertical-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top);
*/

@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

// Set tints and shadows of your colours

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

