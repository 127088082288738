@import "utils";

.header {
  position: relative;
  z-index: 1000;
  opacity: 1;
  transition: opacity .3s ease;

  &__logo {
    display: block;
    width: 300px*$scale;
    height: 60px*$scale;
    margin: $spacing $spacing;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.hide {
  opacity: 0;
}
