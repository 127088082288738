@import "utils";

:export {
  documentScale: $scale;
}

.video {
  width: 1920px * $scale;

  video {
    width: 100%;
  }
}

:global(.fade-enter-active) {
  .controls {
    opacity: 1;
  }
}

:global(.fade-enter-done) {
  .controls {
    opacity: 1;
  }
}

:global(.fade-exit) {
  .controls {
    opacity: 0 !important;
    transition-delay: 0s !important;
    transition: opacity .1s ease !important;
  }
}

// this fixes the issue of the controls not appearing when refreshing the page
// it checks for the condition of when a page does NOT have router transition classes applied
:global(div[class="page"]) {
  .controls {
    opacity: 1;
  }
}

.controls {
  opacity: 0;
  position: absolute;
  right: 50px * $scale;
  bottom: 48px * $scale;
  z-index: 2000;
  height: 52px * $scale;
  transition: opacity .4s ease;
  transition-delay: .3s;

  img {
    @include unselectable;
    height: 38px * $scale;
    width: auto;
    overflow: hidden;
    cursor: pointer;
    transition: opacity .2s ease, transform .4s ease;

    &:active {
      transform: scale(0.7)
    }

    &:not(:first-child) {
      margin-left: 14px * $scale;
    }
  }
}

// ******
// Tap Zone and Circles
// ******

.circleHolder {
  position: absolute;
  pointer-events: none;

  &__noWidth {
    width: 0;
  }
}

.circle {
  border-radius: 100%;
  pointer-events: none;
  border: 5px * $scale solid $pink;
  transition: opacity .3s ease;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &__show {
    opacity: 1;
  }

  &__hidden {
    display: none;
  }
}
